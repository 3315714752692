import {
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAIL,
  COMPANY_SAVE_QUERY,
  NB_RESULTS_MAX
} from '../constants/companyConstants';

import Axios from 'axios';

import { listCritereString } from '../data/string_attributs';
import { CodeAccountType } from '../enums/AccountType';
import { ApiCustomQuery, CustomQuery } from '../models/CustomQuery';
import Cookie from 'js-cookie';
import { Operators } from '../enums/Operator.enum';
import { QuerysFilters } from '../models/QuerysFilters';
import { Tools } from '../screen/tools';

const listCompanys = (
  page: number = 1,
  accountType: CodeAccountType,
  listQuerys: CustomQuery[] = [],
  options: any
) => async (dispatch: (arg0: { type: string; payload?: any; querys?: any }) => void, getState: any) => {
  
  const {
    companysHistorique: { DC_Historical },
  } = getState();

  var date = new Date();
  var startTime = (date).getTime();

  try {
    //console.log("action params offset : ", offset, " accountType ", accountType,
    //  ' listQuerys : ', listQuerys);

    dispatch({ type: COMPANY_LIST_REQUEST });

    const querysFilters  : QuerysFilters = Tools.initializeQuerysFilters(listQuerys);
    const {more_than, equals_to, less_than, and} = querysFilters;

    const querys = { accountType, limit: NB_RESULTS_MAX, offset: (page - 1) * NB_RESULTS_MAX, more_than, equals_to, less_than, and };
    console.log(page, " querys", querys, ' listQuerys : ', listQuerys)

    const { data } = await Axios.post(
      `${process.env.REACT_APP_DATACOMPANIES_API_LINK}customSearch`, querys, options);
  
      const apiCustomQuery: ApiCustomQuery = {
        accountType, page,
        listQuerys, reqestTime: (((new Date()).getTime() - startTime) / 1000).toString(), date: date.toLocaleString(),
        limit: NB_RESULTS_MAX
      };
      const historical: ApiCustomQuery[] = DC_Historical  || [];
      historical.push(apiCustomQuery);
      console.log('historical :>> ', historical);
      // const MAX = 10;
      // if (historical.length > MAX){
      //   console.log("splice remove firsst element list");
      //   historical.splice(0,historical.length - MAX)
      // }
      // console.log('apiCustomQuery :>> ', apiCustomQuery);
      // console.log('historical :>> ', historical);
      var tomorrow = new Date();
      const COOKIE_HISTORY_TIME_DAY = 3;
      tomorrow.setDate(tomorrow.getDate() + COOKIE_HISTORY_TIME_DAY);
      //var in4Hours = 8 / 48;
      Cookie.set('DC_Historical', JSON.stringify(historical), {
        expires: tomorrow
      });

      console.log('DC_Historical :>> ', DC_Historical);

      dispatch({ type: COMPANY_SAVE_QUERY, payload: historical });

    dispatch({ type: COMPANY_LIST_SUCCESS, payload: data, querys: apiCustomQuery });

  } catch (error: any) {

    //Erreur impossible de récupérer la liste des entreprises
    dispatch({ type: COMPANY_LIST_FAIL, payload: error.message });
  }
};

const getUrlDownload = async (type: string, id: string) => {
  //console.log(`requete : http://${IP_DATACOMPANIES}:8080/getCsvFromAccount/${type}/${id}`);
  return fetch(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}getCsvFromAccount/${id}`,
    {
      method: 'GET',
    }
  )

}

const getUrlCSV = async (request: any) => {


  // const request = {
  //   fieds,
  //   ...apiCustomQuery,
  //   limit : NB_RESULTS_MAX,
  //   more_than, equals_to, less_than, and 
  // }
  //   const request2 =     {
  //     "accountType": "C",
  //     "limit": 100,
  //     "offset": 0,
  //     "more_than": {
  //         "FJ.3": 3000
  //     },
  //     "fields": [
  //         "CO.3",
  //         "BJ.3"
  //     ]
  // }

  console.log('request :>> ', request);
  return fetch(`${process.env.REACT_APP_DATACOMPANIES_API_LINK}getResultsInCsv2`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    }
  )

}

export {
  listCompanys,
  getUrlDownload,
  getUrlCSV
};

