import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../actions/userActions';
import './navbar.scss'
import { ApiCustomQuery, CustomQuery } from '../../models/CustomQuery';
import { Tools } from '../../screen/tools';
import { ModalTypes } from '../../enums/ModalTypes.eum';
import { ModalContext } from '../../ModalContext';
import { AiOutlineMenu } from 'react-icons/ai';


function Navbar(props: any) {
  const user = useSelector((state: RootStateOrAny) => state.userSignin);
  const connectActive = user.DC_Info ? user.DC_Info.email && user.DC_Info.email.length > 0 : false;

  const dispatch = useDispatch();

  const disconnect = () => {
    dispatch(logout());
  }

  return (

    <div className="">
      <div className="">
        <nav className="navbar ">
        <Link className="navbar-brand text-light" to="/"> DataCompanies (BETA)</Link>
          <div className="" >
            <ul className="">


              {!connectActive &&
                <>
                  <li className="">
                    <Link className="" to="/register">Inscription</Link>
                  </li>
                  <li className="">
                    <Link className="" to="/signIn">Connexion</Link>
                  </li>
                </>
              }

              {/* --------------------- Bouttons non connecté --------------------------- */}

              <li className="nav-dropdown mt-2">
                <button className={"btn btn-primary border-light " + (!connectActive && "active")}>{connectActive ? user.DC_Info.email :
                  <AiOutlineMenu />}
                </button>
                <div className="dropdown-content">

                  {connectActive ?
                    <>
                      <Link className="dropdown-item" to="/account">Mon compte</Link>
                    </> :
                    <>
                      <Link className="dropdown-item" to="/register">Inscription </Link>
                      <Link className="dropdown-item" to="/signIn">Connexion </Link>
                    </>}
                  <Link className="dropdown-item" to="/about">A propos</Link>
                  {connectActive && <Link className="dropdown-item" to="/" onClick={disconnect} >Déconnexion</Link>}

                </div>
              </li>

              <li className="">
                <Link className="" to="/about">A propos</Link>
              </li>

            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;

